import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModelType,
  CompiereDataGridType
} from '@compiere-ws/models/compiere-data-json';
import { ColumnFilterAutocomplete, OperatorFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { filterOperators, OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual, isNil } from 'lodash';
import * as moment from 'moment';

export class UniversalFilterUtils {
  private static config: AppConfig;
  private static connectorService: SecurityManagerService;
  private static translator: TranslateService;

  public static setConfig(config: AppConfig) {
    this.config = config;
  }

  public static setConnectorService(connectorService: SecurityManagerService) {
    this.connectorService = connectorService;
  }

  public static setTranslateService(translator: TranslateService) {
    this.translator = translator;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * **************************** CHIPS ******************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  public static dataToDisplayToChips(dataToDisplay: DataToDisplay): FilterChip[] {
    const dataToTransform = this.cleanDataToDisplay(cloneDeep(dataToDisplay));
    moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
    const filterChips: FilterChip[] = [];

    for (let index = 0; index < dataToTransform.filters.length; index++) {
      const f = dataToTransform.filters[index];
      const needTime =
        f.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16 ||
        f.column.columnInfo.fieldEntity.field.AD_Reference_ID === 24;
      let filterStr: string;
      let operatorStr: string;
      if (f.operator.operator) {
        if (f.operator.operator.isRange) {
          if (f.filter && f.filterTo) {
            filterStr =
              (f.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(f.filter).format(needTime ? Global.display_dateTimeFormat : Global.display_dateFormat)
                : f.filter) +
              (this.translator ? this.translator.instant('universalFilter.and') : ' and ') +
              (f.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(f.filterTo).format(needTime ? Global.display_dateTimeFormat : Global.display_dateFormat)
                : f.filterTo);
            operatorStr = f.operator.displayValue;
          } else if (f.filter && !f.filterTo) {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(f.filter).format(needTime ? Global.display_dateTimeFormat : Global.display_dateFormat)
                : f.filter;
            operatorStr = filterOperators.find(
              (op) => op.type === OperatorFilterType.BIGGER_EQUALS && op.filterType === f.column.columnInfo.filterType
            ).label;
          } else if (!f.filter && f.filterTo) {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(f.filterTo).format(needTime ? Global.display_dateTimeFormat : Global.display_dateFormat)
                : f.filterTo;
            operatorStr = filterOperators.find(
              (op) => op.type === OperatorFilterType.LESS_EQUALS && op.filterType === f.column.columnInfo.filterType
            ).label;
          }
        } else if (f.filter !== null && f.filter !== undefined) {
          if (Array.isArray(f.filter)) {
            filterStr = f.filter
              .map((item) => (item.displayValue !== undefined ? item.displayValue : item))
              .join(this.translator ? this.translator.instant('universalFilter.or') : ' or ');
            operatorStr = f.operator.displayValue;
          } else {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(f.filter).format(needTime ? Global.display_dateTimeFormat : Global.display_dateFormat)
                : f.filter.displayValue
                ? f.filter.displayValue
                : f.filter;
            operatorStr = f.operator.displayValue;
          }
        }
        if (filterStr !== null && filterStr !== undefined && operatorStr !== null && operatorStr !== undefined) {
          filterChips.push({
            icon: 'icon-ico-filter',
            displayValue: `${f.column.displayValue} ${operatorStr} '${filterStr}'`,
            type: 'filters',
            index,
            columnName: f.column.id
          });
        }
      }
    }

    for (let index = 0; index < dataToTransform.groups.length; index++) {
      const group = dataToTransform.groups[index];
      if (group && group.displayValue) {
        filterChips.push({
          icon: 'icon-ico-group-check',
          displayValue: group.displayValue,
          type: 'groups',
          index
        });
      }
    }

    for (let index = 0; index < dataToTransform.sortings.length; index++) {
      const sorting = dataToTransform.sortings[index];
      if (sorting && sorting.column && sorting.column.displayValue) {
        filterChips.push({
          icon:
            sorting.sortingType === CompiereDataGridSortModelType.ASC
              ? 'icon-ico-tri-az'
              : sorting.sortingType === CompiereDataGridSortModelType.DESC
              ? 'icon-ico-tri-za'
              : 'icon-ico-tri',
          displayValue: sorting.column.displayValue,
          type: 'sortings',
          index
        });
      }
    }

    return filterChips;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************** DATA TO DISPLAY **************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */

  public static compiereDataGridToDataToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    requests: CompiereDataGridRequestJSON[]
  ): DataToDisplay[] {
    const dataToDisplays: DataToDisplay[] = [];
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      dataToDisplays.push(this.singleCompiereDataGridToDataToDisplay(columnFilters, operatorFilters, request));
    }
    return dataToDisplays;
  }

  public static singleCompiereDataGridToDataToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    request: CompiereDataGridRequestJSON
  ): DataToDisplay {
    const dataToDisplay: DataToDisplay = {
      favorite: request.label,
      isDefault: request.isDefault,
      groups: request.rowGroupCols.map((groupCol) => columnFilters.items.find((columnFilter) => groupCol.id === columnFilter.id)),
      sortings: request.sortModel.map((sortModel) => ({
        column: columnFilters.items.find((columnName) => sortModel.colId === columnName.columnInfo.fieldEntity.field.ColumnName),
        sortingType: sortModel.sort
      })),
      filters: [],
      notUFData: { pivotCols: request.pivotCols, valueCols: request.valueCols, pivotMode: request.pivotMode }
    };
    dataToDisplay.filters = this.filterModelToFilterToDisplay(columnFilters, operatorFilters, request);

    return dataToDisplay;
  }

  public static filterModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    f: CompiereDataGridRequestJSON
  ): FilterToDisplay[] {
    const filterToDisplays: FilterToDisplay[] = [];
    if (f.filterModel) {
      const keys = Object.keys(f.filterModel);
      for (let i = 0; i < keys.length; i++) {
        const columnKey = keys[i];
        const base: FilterToDisplay = {
          column: columnFilters.items.find(
            (columnFilter) =>
              columnFilter.columnInfo.fieldEntity.field.ColumnName === columnKey ||
              columnFilter.columnInfo.fieldEntity.field.ColumnSQL === columnKey
          ),
          filter: undefined,
          filterTo: undefined,
          operator: undefined
        };
        const filterModel = f.filterModel[columnKey];
        if (
          filterModel.operators.length > 0 &&
          filterModel.values.length > 0 &&
          filterModel.operators.length === filterModel.values.length
        ) {
          for (let j = 0; j < filterModel.operators.length; j++) {
            const operator: OperatorFilterType = filterModel.operators[j];
            const filterToDisplay: FilterToDisplay = Object.assign({}, base);
            filterToDisplay.operator = operatorFilters.items.find(
              (operatorFilter) =>
                operatorFilter.operator.type === operator &&
                operatorFilter.operator.filterType === f.filterModel[columnKey].filterType
            );
            let values = filterModel.values[j];
            if (
              (filterModel.filterType === CompiereDataGridFilterType.NUMBER ||
                filterModel.filterType === CompiereDataGridFilterType.SET) &&
              !Array.isArray(values) &&
              (operator === OperatorFilterType.EQUALS || operator === OperatorFilterType.NOT_EQUALS)
            ) {
              values = [values + ''];
            }
            filterToDisplay.filter = values;
            if (operator === 'between') {
              filterToDisplay.filterTo = filterModel.values[++j];
            }
            filterToDisplays.push(filterToDisplay);
          }
        }
      }
    }
    return filterToDisplays;
  }
  public static sortModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): SorterToDisplay[] {
    const sorterToDisplays: SorterToDisplay[] = [];
    if (s.sortModel) {
      for (let i = 0; i < s.sortModel.length; i++) {
        const sortModelTmp = s.sortModel[i];
        const columnFound = columnFilters.items.find(
          (columnFilter) => columnFilter.columnInfo.fieldEntity.field.ColumnName === sortModelTmp.colId
        );
        const base: SorterToDisplay = {
          column: columnFound ? columnFound : { id: sortModelTmp.colId, displayValue: sortModelTmp.colId, columnInfo: null },
          sortingType: undefined
        };
        const sortToDisplay: SorterToDisplay = Object.assign({}, base);
        sortToDisplay.sortingType = sortModelTmp.sort;
        sorterToDisplays.push(sortToDisplay);
      }
    }
    return sorterToDisplays;
  }

  public static groupModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): ColumnFilterAutocomplete[] {
    const groupToDisplays: ColumnFilterAutocomplete[] = [];
    if (s.rowGroupCols) {
      for (let i = 0; i < s.rowGroupCols.length; i++) {
        const groupColTmp = s.rowGroupCols[i];
        const base = columnFilters.items.find((columnFilter) => columnFilter.id === groupColTmp.id);
        const groupToDisplay: ColumnFilterAutocomplete = Object.assign({}, base);
        groupToDisplays.push(groupToDisplay);
      }
    }
    return groupToDisplays;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************* COMPIERE DATA GRID ************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  public static dataToDisplayToCompiereDataGrid(dataToDisplay: DataToDisplay, tabID: number): CompiereDataGridRequestJSON {
    const dataToTransform: DataToDisplay = this.transformDisplayOperators(cloneDeep(dataToDisplay));
    dataToTransform.filters = dataToTransform.filters.filter(
      (f) =>
        (f.filter !== undefined && f.filter !== null && f.filter !== '') ||
        (f.filterTo !== undefined && f.filterTo !== null && f.filterTo !== '')
    );
    return {
      label: dataToTransform.favorite,
      filterModel: this.filterToDisplayToFilterModel(dataToTransform),
      startRow: 0,
      endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize'),
      entityId: tabID,
      windowType: CompiereDataGridType.WINDOW,
      rowGroupCols: dataToTransform.groups
        .filter((group) => group && Object.keys(group).length > 0 && group.id !== -1)
        .map((group) => ({
          id: group.id,
          displayName: group.displayValue,
          field: group.columnInfo.fieldEntity.field.ColumnName,
          aggFunc: ''
        })),
      sortModel: dataToTransform.sortings
        .filter((sorting) => sorting.column && sorting.column.id !== -1)
        .map((sorting) => ({
          colId: sorting.column.columnInfo ? sorting.column.columnInfo.fieldEntity.field.ColumnName : sorting.column.id,
          sort: sorting.sortingType
        })),
      pivotCols: dataToDisplay.notUFData ? (dataToDisplay.notUFData.pivotCols ? dataToDisplay.notUFData.pivotCols : []) : [],
      valueCols: dataToDisplay.notUFData ? (dataToDisplay.notUFData.valueCols ? dataToDisplay.notUFData.valueCols : []) : [],
      pivotMode: dataToDisplay.notUFData ? (dataToDisplay.notUFData.pivotMode ? true : false) : false
    };
  }

  public static filterToDisplayToFilterModel(dataToTransform: DataToDisplay): {
    [columnName: string]: CompiereDataGridFilterModel;
  } {
    const filterModel: { [columnName: string]: CompiereDataGridFilterModel } = {};
    const filters = dataToTransform.filters.filter((f) => f.column.id !== -1 && f.column.displayValue);

    for (let i = 0; i < filters.length; i++) {
      const f = filters[i];
      const columnName = f.column.columnInfo.fieldEntity.ColumnName
        ? f.column.columnInfo.fieldEntity.ColumnName
        : f.column.columnInfo.fieldEntity.field.ColumnName;
      const values =
        f.operator.operator.filterType === CompiereDataGridFilterType.DATE
          ? moment(f.filter).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26)
          : Array.isArray(f.filter)
          ? f.filter.map((item) => (item instanceof Object ? item.id : item))
          : f.filter instanceof Object
          ? f.filter.id
          : f.operator.operator.type === 'contains'
          ? f.filter.trim()
          : f.filter;
      if (filterModel.hasOwnProperty(columnName)) {
        filterModel[columnName].values.push(values);
        filterModel[columnName].operators.push(f.operator.operator.type);
      } else {
        filterModel[columnName] = {
          filterType: f.operator.operator.filterType,
          values: [values],
          operators: [f.operator.operator.type]
        };
      }
      if (f.operator.operator.filterType === CompiereDataGridFilterType.NUMBER) {
        for (let j = 0; j < filterModel[columnName].values.length; j++) {
          if (Array.isArray(filterModel[columnName].values[j])) {
            filterModel[columnName].values[j] = filterModel[columnName].values[j].map((value) => {
              if (typeof value === 'string') {
                value = value.replace(',', '.');
              }
              if (!isNaN(parseFloat(value))) {
                value = parseFloat(value);
              } else {
                value = 0;
              }
              return value;
            });
          } else {
            if (filterModel[columnName].values[j] instanceof String) {
              filterModel[columnName].values[j] = filterModel[columnName].values[j].replace(',', '.');
            }
            if (!isNaN(parseFloat(filterModel[columnName].values[j]))) {
              filterModel[columnName].values[j] = parseFloat(filterModel[columnName].values[j]);
            } else {
              filterModel[columnName].values[j] = 0;
            }
          }
        }
      }
    }
    return filterModel;
  }

  private static transformDisplayOperators(dataToDisplay: DataToDisplay): DataToDisplay {
    if (dataToDisplay.filters.filter((f) => f.column.id === -1 || f.operator.id === -1).length === 0) {
      const betweens = dataToDisplay.filters
        .map((f) => (f.operator.operator.type === 'between' ? f : undefined))
        .filter((f) => f !== undefined);

      for (let i = 0; i < betweens.length; i++) {
        const f = betweens[i];
        const index = dataToDisplay.filters.findIndex((fil) => fil === f);
        if (f.filter !== undefined && f.filterTo !== undefined && f.filter !== null && f.filterTo !== null) {
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: f.operator
          };
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: f.operator
          };
          dataToDisplay.filters.splice(index, 1, borneInf, borneSup);
        } else if (f.filter !== undefined && f.filter !== null && (f.filterTo === undefined || f.filterTo === null)) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.BIGGER_EQUALS && op.filterType === f.column.columnInfo.filterType
          );
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator
            }
          };
          dataToDisplay.filters.splice(index, 1, borneInf);
        } else if ((f.filter === undefined || f.filter === null) && f.filterTo !== undefined && f.filterTo !== null) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.LESS_EQUALS && op.filterType === f.column.columnInfo.filterType
          );
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator
            }
          };
          dataToDisplay.filters.splice(index, 1, borneSup);
        }
      }
    }
    return cloneDeep(dataToDisplay);
  }

  /*
   * ************************************************************
   * ************************************************************
   * ************************************************************
   * ******************** FILTER UTILS **************************
   * ************************************************************
   * ************************************************************
   * ************************************************************
   */

  public static cleanDataToDisplay(dataToDisplay: DataToDisplay) {
    dataToDisplay.filters = dataToDisplay.filters.filter(
      (filter) =>
        filter.column &&
        filter.operator &&
        (filter.column.id !== -1 ||
          filter.operator.id !== -1 ||
          filter.filter !== '' ||
          (filter.operator.id !== -1 && filter.operator.operator.isRange && filter.filterTo !== ''))
    );
    dataToDisplay.groups = dataToDisplay.groups.filter((group) => group.id !== -1);
    dataToDisplay.sortings = dataToDisplay.sortings.filter((sorting) => sorting.column && sorting.column.id !== -1);
    return dataToDisplay;
  }

  public static isEqualDataToDisplay(source: DataToDisplay, compareTo: DataToDisplay): boolean {
    if (source && compareTo) {
      const filterMapper = (_filter: FilterToDisplay) => {
        const keys = ['filter', 'filterTo'];
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (_filter.operator.operator && (key === 'filter' || _filter.operator.operator?.isRange)) {
            _filter[key] =
              _filter.operator.operator.filterType === CompiereDataGridFilterType.DATE
                ? moment(_filter[key]).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26)
                : Array.isArray(_filter[key])
                ? _filter[key].map((item) => (item instanceof Object ? item.id : item))
                : _filter[key] instanceof Object
                ? _filter[key].id
                : _filter.operator.operator.type === 'contains'
                ? _filter[key].trim()
                : _filter[key];
          }
        }
        return _filter;
      };

      const filterPredicate = (_filter: FilterToDisplay) =>
        !isNil(_filter.filter) && (!_filter.operator.operator?.isRange || !isNil(_filter.filterTo));

      const sourceFilters = source.filters.filter(filterPredicate).map(filterMapper);
      const sourceGroups = source.groups.filter((_group) => _group);
      const sourceSortings = source.sortings.filter((_sorting) => _sorting);

      const compareToFilters = compareTo.filters.filter(filterPredicate).map(filterMapper);
      const compareToGroups = compareTo.groups.filter((_group) => _group);
      const compareToSortings = compareTo.sortings.filter((_sorting) => _sorting);

      return (
        isEqual(sourceFilters, compareToFilters) &&
        isEqual(sourceGroups, compareToGroups) &&
        isEqual(sourceSortings, compareToSortings)
      );
    } else {
      return false;
    }
  }
}

export interface DataToDisplay {
  isDefault?: boolean;
  favorite: string;
  groups: ColumnFilterAutocomplete[];
  filters: FilterToDisplay[];
  sortings: SorterToDisplay[];
  notUFData: { pivotCols?: CompiereDataGridGroupModel[]; valueCols?: CompiereDataGridGroupModel[]; pivotMode?: boolean };
}

export interface SorterToDisplay {
  column: ColumnFilterAutocomplete;
  sortingType: CompiereDataGridSortModelType;
}

export interface FilterToDisplay {
  column: ColumnFilterAutocomplete;
  operator: OperatorFilterAutocomplete;
  filter: any;
  filterTo?: any;
}

export interface FilterChip {
  displayValue: string;
  icon: string;
  type: 'filters' | 'groups' | 'sortings';
  index: number;
  columnName?: string;
}
